// extracted by mini-css-extract-plugin
export var aiChat__preview = "b5_nj";
export var aiChat__previewButton = "b5_ns";
export var aiChat__previewButtonWrapper = "b5_nr";
export var aiChat__previewContainer = "b5_nk";
export var aiChat__previewDescr = "b5_nq";
export var aiChat__previewFixedBottom = "b5_nt";
export var aiChat__previewLogo = "b5_nn";
export var aiChat__previewLogoWrapper = "b5_nl";
export var aiChat__previewTitle = "b5_np";
export var pulseLightRed = "b5_nm";