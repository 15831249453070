// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "bW_mz";
export var aiChat__animate = "bW_mn";
export var aiChat__animate_content = "bW_mp";
export var aiChat__answer = "bW_mw";
export var aiChat__content = "bW_mr";
export var aiChat__icon = "bW_mq";
export var aiChat__loadingState = "bW_ms";
export var aiChat__message = "bW_mm";
export var aiChat__question = "bW_mv";
export var aiChat__sender = "bW_mx";
export var aiChat__senderInfo = "bW_my";
export var pulseSize = "bW_mt";